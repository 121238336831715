import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as JOB from "../../actions/Job/Job.actions";

const initialState = {
  jobs: null,
  jobObj: null,
  loading: false,
  error: null,
};

export const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOB.JOB_ADD:
      return {
        ...state,
        loading: true,
      };
    case JOB.JOB_ADD_SUCCESS:
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case JOB.JOB_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case JOB.GET_ALL_JOB:
      return {
        ...state,
        loading: true,
      };
    case JOB.GET_ALL_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        jobs: action.payload.data,
      };
    case JOB.GET_ALL_JOB_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case JOB.DELETE_JOB_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case JOB.DELETE_JOB_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case JOB.DELETE_JOB_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case JOB.UPDATE_JOB_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case JOB.UPDATE_JOB_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case JOB.UPDATE_JOB_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case JOB.SET_JOB_OBJ:
      return {
        ...state,
        loading: true,
      };
    case JOB.SET_JOB_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        jobObj: action.payload.data,
        loading: false,
        error: null,
      };
    case JOB.SET_JOB_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
