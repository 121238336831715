import React from "react";
import { images } from "../Images/Images";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

import { RiDashboardLine } from "react-icons/ri";
import { FaLocationDot, FaRankingStar } from "react-icons/fa6";
import { BsCollection } from "react-icons/bs";
import { FaBriefcase } from "react-icons/fa"
import { IoFastFoodOutline } from "react-icons/io5";
import { RiCoupon3Line } from "react-icons/ri";
import { LiaHotelSolid } from "react-icons/lia";
import { MdOutlineContactPhone, MdOutlineManageAccounts, MdOutlineManageSearch, MdSettings } from "react-icons/md";
import { FaBlog } from "react-icons/fa6"
import { TbPhoneCall } from "react-icons/tb";


function SideBar({ style }) {
  let role = useSelector((state) => state.auth.role);

  let location = useLocation();
  const [sidebar_item, setsidebar_item] = useState([
    {
      isrotated: true,
      active: true,
      name: "dashboard",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/",
      icon: <RiDashboardLine />,
      children: [],
    },

    {
      isrotated: true,
      active: false,
      name: "Location",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Location/View-Location",
      icon: <FaLocationDot />,
      children: [],
    },
    {
      isrotated: true,
      active: false,
      name: "Collection",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Collection/View-Collection",
      icon: <BsCollection />,
      children: [],
    },

    {
      isrotated: true,
      active: false,
      name: "Amenity",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Amenities/View",
      icon: <IoFastFoodOutline />,
      children: [],
    },

    {
      isrotated: true,
      active: false,
      name: "Job",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Job/View-Job",
      icon: <FaBriefcase />,
      children: [],
    },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Amenity",

    //   roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
    //   path: "/Amenities/View-category",
    //   icon: <IoFastFoodOutline />,
    //   children: [
    //     {
    //       name: "Amenity category",
    //       roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
    //       path: "/Amenities/View-category",
    //       active: false,
    //     },
    //     {
    //       name: "Amenity",
    //       roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
    //       path: "/Amenities/View",
    //       active: false,
    //     },
    //   ],
    // },
  

    {
      isrotated: true,
      active: false,
      name: "Coupon",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Coupon/View-Coupon",
      icon: <RiCoupon3Line />,
      children: [],
    },
    {
      isrotated: true,
      active: false,
      name: "Seo",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Seo/View-Seo",
      icon: <FaRankingStar />,
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "Property",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Dashboard",
      icon: <LiaHotelSolid />,
      children: [
       
        {
          name: "Hotels",
          path: "/Propertys/View?type=Hotels",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
        {
          name: "Apartments",
          path: "/Propertys/View?type=Home Stays",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
        {
          name: "Property Available",
          path: "Propertys/PropertyAvailable",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
        {
          roleArr: ["ADMIN", "SUBADMIN"],
          name: "Property Review",
          path: "/Review/Product-Review",
          active: false,
        },
      ],
    },
 
    {
      isrotated: false,
      active: false,
      name: "Booking Manage",
      path: "/Dashboard",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      icon: <MdOutlineManageSearch />,
      children: [
        {
          name: "Booking",
          path: "/Booking/Total-Booking",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
     
      ],
    },

 
    {
      isrotated: false,
      active: false,
      name: "Frontend CMS",
      path: "/Dashboard",
      roleArr: ["ADMIN", "SUBADMIN"],
      icon: <MdOutlineManageAccounts />,
      children: [
        {
          name: "HomePage",
          path: "/HomePage",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
        {
          name: "Banners",
          path: "/Banners",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
        {
          name: "Testimonial",
          path: "/Testimonial/View-Testimonial",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
       
        {
          name: "Home Faq",
          path: "/Faq/Home",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
        {
          name: " Property Listing Faq",
          path: "/Faq/PropertyListing",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Blog",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Dashboard",
      icon:<FaBlog />,
      children: [
        {
          name: "Blog",
          path: "/Blog/post",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
        {
          name: "blog category",
          path: "/Blog/Category",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Property Enquiry",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Property",
      icon: <MdOutlineContactPhone />,
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "Contact Query",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Contact",
      icon: <TbPhoneCall />,
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "Setting",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Setting",
      icon: 
<MdSettings />,
      children: [],
    },
   
    {
      isrotated: false,
      active: false,
      name: "Contact Request",
      path: "/Dashboard",
      icon: "fa-solid fa-user",
      children: [
        {
          name: "Contact Mail",
          path: "/Contact-Mail",
          active: false,
        },
      ],
    },
   

    {
      isrotated: false,
      active: false,
      name: "Setup",
      path: "/",
      icon: "ion-settings",
      children: [
        {
          name: "Currency List",
          path: "/SetUp/Currency-List",
          active: false,
        },
        {
          name: "Location",
          path: "/SetUp/Location",
          active: false,
        },
        {
          name: "Tags",
          path: "/SetUp/Tags",
          active: false,
        },
      ],
    },
   
  ]);

  const isRotating = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (index === i) {
        el.isrotated = !el.isrotated;
        el.active = true;
      } else {
        el.active = false;
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  const childActive = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (el.children && el.children.length > 0) {
        el.children.map((item, childIndex) => {
          if (childIndex === i) {
            item.active = true;
          } else {
            item.active = false;
          }
          return item;
        });
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  return (
    <div id="sidebar">
      <div
        className="main-logo my-3"
        style={style ? { padding: "26px 10px", height: "auto" } : {}}
      >
        {style ? (
          <img src={images?.favicon} alt="" />
        ) : (
          <img style={{ width: 150 }} src={images?.logo} alt="" />
        )}
      </div>
      <ul className="sidebar-menu" id="sidebarMenu">
        {sidebar_item &&
          sidebar_item?.map((item, i) => {
            if (
              typeof array === "undefined" &&
              item?.children &&
              item?.children?.length === 0 &&
              item?.roleArr.some(
                (el) => `${el}`.toLowerCase() == `${role}`.toLowerCase()
              )
            ) {
              return (
                <li key={`sidebar_item_${i}`}>
                  <Link
                    to={item?.path}
                    className={item?.active ? "active" : ""}
                    onClick={() => isRotating(i)}
                  >
                    <span className="sidebar_icn">{item.icon}</span>
                    {!style && <p className="mb-0">{item?.name}</p>}
                  </Link>
                </li>
              );
            } else {
              if (
                item?.roleArr &&
                item?.roleArr?.length > 0 &&
                item?.roleArr.some(
                  (el) => `${el}`.toLowerCase() == `${role}`.toLowerCase()
                )
              ) {
                return (
                  <li key={`sidebar_item_${i}`}>
                    <Link
                      to={`#sidebar_item_children_${i}`}
                      className={
                        item?.active || location === item?.path ? "active" : ""
                      }
                      data-bs-toggle="collapse"
                      aria-expanded={item?.active}
                      aria-controls={`sidebar_item_children_${i}`}
                      role="button"
                      onClick={() => isRotating(i)}
                    >
                     <span className="sidebar_icn">{item.icon}</span>
                      {!style && (
                        <p className="mb-0">
                          {item?.name}
                          {item?.isrotated ? (
                            <i className="ion-arrow-up-b pe-3"></i>
                          ) : (
                            <i className="ion-arrow-down-b pe-3"></i>
                          )}
                        </p>
                      )}
                    </Link>
                    {!style && (
                      <ul
                        className="collapse"
                        id={`sidebar_item_children_${i}`}
                        data-bs-parent="#sidebarMenu"
                      >
                        {item?.children?.map((child, index) => {
                          if (
                            child?.roleArr &&
                            child?.roleArr?.length > 0 &&
                            child?.roleArr?.some(
                              (el) =>
                                `${el}`?.toLowerCase() ==
                                `${role}`?.toLowerCase()
                            )
                          ) {
                            return (
                              <li key={`${child?.name}_${index}`}>
                                <Link
                                  to={child?.path}
                                  className={
                                    child?.active || location === child?.path
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() => childActive(index)}
                                >
                                  {child?.name}
                                </Link>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    )}
                  </li>
                );
              }
            }
          })}
      </ul>
    </div>
  );
}

export default SideBar;
