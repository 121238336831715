import { addJob, deleteJob, getJob, updateJob } from "../../../services/Job.service";

export const JOB_ADD = "JOB_ADD";
export const JOB_ADD_SUCCESS = "JOB_ADD_SUCCESS";
export const JOB_ADD_FAIL = "JOB_ADD_FAIL";

export const GET_ALL_JOB = "GET_ALL_JOB";
export const GET_ALL_JOB_SUCCESS = "GET_ALL_JOB_SUCCESS";
export const GET_ALL_JOB_FAIL = "GET_ALL_JOB_FAIL";

export const UPDATE_JOB_BY_ID = "UPDATE_JOB_BY_ID";
export const UPDATE_JOB_BY_ID_SUCCESS = "UPDATE_JOB_BY_ID_SUCCESS";
export const UPDATE_JOB_BY_ID_FAIL = "UPDATE_JOB_BY_ID_FAIL";

export const SET_JOB_OBJ = "SET_JOB_OBJ";
export const SET_JOB_OBJ_SUCCESS = "SET_JOB_OBJ_SUCCESS";
export const SET_JOB_OBJ_FAIL = "SET_JOB_OBJ_FAIL";

export const GET_JOB_BY_ID = "GET_JOB_BY_ID";
export const GET_JOB_BY_ID_SUCCESS = "GET_JOB_BY_ID_SUCCESS";
export const GET_JOB_BY_ID_FAIL = "GET_JOB_BY_ID_FAIL";

export const DELETE_JOB_BY_ID = "DELETE_JOB_BY_ID";
export const DELETE_JOB_BY_ID_SUCCESS = "DELETE_JOB_BY_ID_SUCCESS";
export const DELETE_JOB_BY_ID_FAIL = "DELETE_JOB_BY_ID_FAIL";

export const JobAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: JOB_ADD });
    let { data: response } = await addJob(formData);
    if (response) {
     
      dispatch({
        type: JOB_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch(JobGet())
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: JOB_ADD_FAIL, payload: err });
  }
};

export const JobGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_JOB });
    let { data: response } = await getJob(formData);
    if (response) {

      dispatch({
        type: GET_ALL_JOB_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: JOB_ADD_FAIL, payload: err });
  }
};

export const SetJobObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_JOB_OBJ });
    if (formData) {
      dispatch({
        type: SET_JOB_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_JOB_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_JOB_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const JobUpdate = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_JOB_BY_ID });
    let { data: response } = await updateJob(formData, id);
    if (response) {
      console.log(response);
      dispatch({
        type: UPDATE_JOB_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(JobGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_JOB_BY_ID_FAIL, payload: err });
  }
};

export const JobDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_JOB_BY_ID });
    let { data: response } = await deleteJob(id);
    if (response) {
      console.log(response, "response");
    
      dispatch({
        type: DELETE_JOB_BY_ID_SUCCESS,
        payload: response.message
      });
      dispatch(JobGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_JOB_BY_ID_FAIL, payload: err });
  }
};